import React, { useState, createContext, useEffect } from "react";
import { useFetch } from "hooks";
import { get, put, post } from "utils/api";
import { path } from "ramda";
import { errorMessage, message as sendToasterMessage } from "actions/message";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";import { getApiErrorMessage } from 'utils/misc';
import { browserHistory } from "browser-history";

export const InsuranceCompanyContext = createContext();

const InsuranceCompanyProvider = ({ children, companyGuid }) => {
  const [selectedInsurance, setSelectedInsurance] = useState("");
  const hcpcQuery = { insurance: selectedInsurance, guid: companyGuid };
  const [initialBulkValues, setInitialBulkValues] = useState({
    insuranceGUIDs: [],
    insuranceListToUpdate: {},
    primaryInsuranceToCopy: ""
  });

  useEffect(() => {}, [initialBulkValues]);

  const {
    response: insurance,
    fetchData: getInsurance,
    isFetching: isFetchingInsurance
  } = useFetch({
    defaultValue: [],
    apiFn: companyGuid => get(`companies/${companyGuid}/insurances`),
    transformError: path(["response", "body", "status"]),
    onError: () => {
      errorMessage(`Failed to pull insurances`);
    }
  });
  const {
    response: hcpcs,
    fetchData: getHCPCs,
    isFetching: isFetchingHcpcs
  } = useFetch({
    defaultValue: [],
    apiFn: hcpcQuery =>
      put(`companies/${hcpcQuery.insurance}/hcpcs`, {
        companyGuid: hcpcQuery.guid
      }),
    transformError: path(["response", "body", "status"]),
    onError: () => {
      errorMessage(`Failed to pull Hcpcs`);
    }
  });
  const {
    response: formSubmit,
    fetchData: SubmitBulkUpdateForm,
    isFetching: isFetchingBulkSubmit
  } = useFetch({
    defaultValue: [],
    apiFn: async formData =>
      put(`companies/form_submit_bulk_update_insurance`, formData),
    transformError: path(["response", "body", "status"]),
    onSuccess: () => {
        dispatch(sendToasterMessage("Insurance Companies Successfully Updated"));
    },
    onError: error => {
      dispatch(errorMessage(`Failed to update insurance companies ${error.message}`));
      throw new Error (error)
    }
  });
  const { fetchData: UpdateHCPCEligibility } = useFetch({
    defaultValue: [],
    apiFn: payload => put("companies/update_hcpc_eligibility", payload),
    onSuccess: () => getHCPCs(hcpcQuery),
    transformError: path(["response", "body", "status"]),
    onError: error => {
      dispatch(errorMessage(getApiErrorMessage(error)));
    }
  });
  const dispatch = useDispatch();
  const { fetchData: updateInsurance, isFetching: isUpdatingInsurance } =
    useFetch({
      defaultValue: [],
      apiFn: payload => put("companies/update_insurance", payload),
      onSuccess: insuranceResponse => {
        insuranceResponse.failures?.map(ir => {
          dispatch(errorMessage(ir));
        });
        insuranceResponse.successes?.map(ir =>
          dispatch(sendToasterMessage(ir))
        );
        if(hcpcQuery && hcpcQuery.insurance)
          getHCPCs(hcpcQuery);
        getInsurance(companyGuid);
      },
      transformError: path(["response", "body", "status"]),
      onError: () => {
        dispatch(errorMessage(`Failed to update insurance`));
      }
    });

    const { fetchData: initiateCompanyInsuranceExport } = useFetch({
      defaultValue: [],
      apiFn: (payload) =>
        post(`companies/export_company_insurances`, payload),
      onSuccess: insuranceResponse => {
        insuranceResponse.failures?.map(ir => {
          dispatch(errorMessage(ir));
        });
        insuranceResponse.successes?.map(ir => dispatch(sendToasterMessage(ir)));
        browserHistory.push("/exports");
      },
      transformError: path(["response", "body", "status"]),
      onError: error => {
        errorMessage(`Failed to export insurances: ${error.message}`);
      }
    });

  const {
    response: hcpcsList,
    fetchData: getBulkUpdateInsurances,
    isFetching: isFetchingBulkUpdateList
  } = useFetch({
    defaultValue: [],
    apiFn: payload =>
      put(`companies/${companyGuid}/bulk_update_insurance`, payload),
    onSuccess: insuranceResponse => {
      insuranceResponse.failures?.map(ir => {
        dispatch(errorMessage(ir));
      });
      insuranceResponse.successes?.map(ir => dispatch(sendToasterMessage(ir)));
      setInitialBulkValues(previousState => {
        const previousStateCopy = { ...previousState };
        previousStateCopy.insuranceListToUpdate = insuranceResponse;
        return previousStateCopy;
      });
    },
    transformError: path(["response", "body", "status"]),
    onError: error => {
      errorMessage(`Failed to update insurances: ${error.message}`);
    }
  });

  return (
    <InsuranceCompanyContext.Provider
      value={{
        getInsurance,
        selectedInsurance,
        setSelectedInsurance,
        insurance,
        hcpcs,
        getHCPCs,
        isFetchingHcpcs,
        UpdateHCPCEligibility,
        updateInsurance,
        companyGuid,
        isFetchingInsurance,
        isUpdatingInsurance,
        hcpcsList,
        SubmitBulkUpdateForm,
        isFetchingBulkUpdateList,
        getBulkUpdateInsurances,
        isFetchingBulkSubmit,
        formSubmit,
        initiateCompanyInsuranceExport
      }}
    >
      {children}
    </InsuranceCompanyContext.Provider>
  );
};

export default InsuranceCompanyProvider;
InsuranceCompanyProvider.propTypes = {
  children: PropTypes.node,
  companyGuid: PropTypes.string
};
